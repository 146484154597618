import { NgModule } from '@angular/core';

import { FeatherModule } from 'angular-feather';
import {
  User,
  Home,
  Mail,
  Phone,
  ArrowLeft,
  ArrowDown,
  MapPin,
  Calendar,
  Lock,
  Eye,
  EyeOff,
  Check,
  Search,
  ChevronDown,
  ChevronLeft,
  ChevronRight,
  Book,
  Award,
  BellOff,
  Bookmark,
  MoreVertical,
  Flag,
  AlertOctagon,
  Facebook,
  Instagram,
  Linkedin,
  Youtube,
  Menu,
  Github,
  CheckCircle,
  Share2,
  ArrowUpRight,
  Briefcase,
  Box,
  DollarSign,
  Folder,
  Clipboard,
  Link,
  Shield,
  Smile,
  Thermometer,
  Server,
  Star,
  LogIn,
  PlayCircle,
  Clock,
  Feather,
  CreditCard,
  Coffee,
  Monitor,
  Info,
  X,
  Gift,
  FileText,
  Users,
  ThumbsUp,
  AlertCircle,
  BarChart2,
  Code,
  Plus,
  ChevronUp,
  Heart,
  Zap,
  FileMinus,
  Camera,
  Download,
  Power,
  Headphones,
  Codepen,
  ArrowRight,
  Layers,
  Bell,
  AlertTriangle,
  Terminal,
  XCircle,
  ExternalLink,
  Globe,
  MessageSquare,
  RefreshCcw,
  TrendingUp,
  AtSign,
  Airplay,
  ArrowRightCircle,
} from 'angular-feather/icons';

const icons = {
  Airplay,
  ExternalLink,
  User,
  Home,
  Mail,
  Phone,
  ArrowLeft,
  ArrowDown,
  MapPin,
  Calendar,
  Lock,
  Eye,
  EyeOff,
  Check,
  Search,
  ChevronDown,
  Book,
  Award,
  Bell,
  Bookmark,
  MoreVertical,
  Flag,
  AlertOctagon,
  Facebook,
  Instagram,
  Linkedin,
  Youtube,
  Menu,
  Github,
  CheckCircle,
  Share2,
  ArrowUpRight,
  Briefcase,
  Box,
  DollarSign,
  Folder,
  Clipboard,
  Link,
  Shield,
  Smile,
  Thermometer,
  Server,
  Star,
  LogIn,
  PlayCircle,
  Clock,
  Feather,
  CreditCard,
  Coffee,
  Monitor,
  Info,
  X,
  Gift,
  FileText,
  Users,
  ThumbsUp,
  AlertCircle,
  BarChart2,
  Code,
  Plus,
  ChevronUp,
  ChevronLeft,
  ChevronRight,
  Heart,
  Zap,
  FileMinus,
  Camera,
  Download,
  Power,
  Headphones,
  Codepen,
  ArrowRight,
  Layers,
  AlertTriangle,
  Terminal,
  XCircle,
  MessageSquare,
  Globe,
  RefreshCcw,
  TrendingUp,
  AtSign,
  ArrowRightCircle,
};

@NgModule({
  imports: [FeatherModule.pick(icons)],
  exports: [FeatherModule],
})
export class IconsModule {}
