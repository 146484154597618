<app-loader></app-loader>

<mat-drawer-container class="example-container" [hasBackdrop]="true">
  <mat-drawer class="drawer" #drawer mode="over">
    <div class="menu-responsive">
      <div class="menu-responsive__header">
        <div class="menu-responsive__header__logo">
          <img src="/assets/homeAssets/Escalab-circle.svg" alt="Escalab" />
        </div>
      </div>

      <div class="menu-responsive__buttons">
        <!-- Hidden to this momment -->
        <!-- <a class="menu-responsive__buttons__button" routerLink="/blog">Blog</a> -->

        <div class="content__items--navMobile">
          <ng-container *ngIf="urlActiveBus == true">
            <div class="dropdownMenu">
              <button routerLink="/">Inicio</button>
              <expansion-panel [content]="developers_routes_content" />
              <!-- <button routerLink="/services/prices">Precios</button> -->
              <expansion-panel [content]="events_routes_content" />
            </div>

            <!-- Optional button for routes -->
            <a
              *ngIf="this.contactButton"
              class="menu-responsive__buttons__expert"
              routerLink="/contact/cesar"
            >
              <img
                src="/assets/homeAssets/icon-user-blue.svg"
                alt="Expert logo"
              />
              Agenda con un asesor
            </a>
          </ng-container>
        </div>
      </div>
    </div>
  </mat-drawer>

  <mat-drawer-content>
    <!-- BANNER -->

    <div class="menu">
      <!-- Banner if the city is equal to someone as Sumanti cover -->

      <escalab-banner [TargetOutPage]="true"></escalab-banner>

      <escalab-banner
        [TargetOutPage]="false"
        *ngIf="isColombia && canBeSumanti"
      ></escalab-banner>

      <div class="menu-recruitment">
        <div routerLink="/" class="menu-recruitment__logo">
          <img [src]="urlAssets2 + 'Logo_escalab.svg'" alt="" />
        </div>

        <!-- Navbar menu with all options on the site -->
        <div
          class="menu-recruitment__buttons"
          *ngIf="
            !isCheckoutRoute &&
            !isProductRoute &&
            !isCloudSpecialist &&
            !isWorkshop &&
            !isAzureDataIa
          "
        >
          <!-- Simple route buttons -->
          <a class="simpleButtons" routerLink="/">Inicio</a>

          <!-- Routers buttons with the dropdowns -->
          <router-button [content]="developers_routes_content" />
          <!-- <a class="simpleButtons" routerLink="/services/prices">Precios</a> -->
          <router-button [content]="events_routes_content" />
        </div>
        <a
          *ngIf="
            this.contactButton &&
            !isCloudSpecialist &&
            !isWorkshop &&
            !isAzureDataIa
          "
          class="expert"
          routerLink="/contact/cesar"
        >
          <img src="/assets/homeAssets/icon-user-blue.svg" alt="Expert logo" />
          Agenda con un asesor
        </a>

        <div
          class="container_is_cloud_specialist"
          *ngIf="isCloudSpecialist || isWorkshop || isAzureDataIa"
        >
          <span class="discount_cloud_specialist"> 10% OFF </span>

          <div
            *ngIf="isCloudSpecialist && !isAzureDataIa"
            class="discount-cifres"
          >
            <p>$2.160.000 CLP</p>
            <span> $2.400.000 CLP </span>
          </div>

          <div
            *ngIf="isAzureDataIa && !isCloudSpecialist"
            class="discount-cifres"
          >
            <p>
              {{ dinamycPrice?.country_currency?.currency?.symbol }}
              <ng-container
                *ngIf="
                  dinamycPrice?.country_currency?.country?.iso_code_alpha2 !==
                  'PE'
                "
              >
                {{
                  discountPrice
                    | currency
                      : dinamycPrice?.country_currency?.currency?.country_code
                      : "code"
                      : "1.0-0"
                }}
              </ng-container>
              <ng-container
                *ngIf="
                  dinamycPrice?.country_currency?.country?.iso_code_alpha2 ===
                  'PE'
                "
              >
                {{ discountPrice }}
              </ng-container>
            </p>

            <!-- normal price -->
            <span>
              {{ dinamycPrice?.country_currency?.currency?.symbol }}
              <ng-container
                *ngIf="
                  dinamycPrice?.country_currency?.country?.iso_code_alpha2 !==
                  'PE'
                "
              >
                {{
                  dinamycPrice?.value
                    | currency
                      : dinamycPrice?.country_currency?.currency?.country_code
                      : "code"
                      : "1.0-0"
                      : ""
                }}
              </ng-container>

              <ng-container
                *ngIf="
                  dinamycPrice?.country_currency?.country?.iso_code_alpha2 ===
                  'PE'
                "
              >
                {{ dinamycPrice?.value }}
              </ng-container>
            </span>
          </div>

          <button
            class="bootcamp_redirect_button"
            (click)="
              movePageToTarget(
                isCloudSpecialist || isAzureDataIa ? 'form' : 'inversion_node'
              )
            "
          >
            {{
              isCloudSpecialist || isAzureDataIa
                ? "Certifica a tu "
                : "Comprar "
            }}
            <span class="word_bootcamp">{{
              !isWorkshop ? "equipo" : "workshop"
            }}</span>
          </button>
        </div>

        <div class="checkout__title" *ngIf="isCheckoutRoute">
          <div class="text">Pago seguro</div>
          <i-feather class="lock" name="lock"></i-feather>
        </div>

        <div
          class="menu-recruitment__icon-menu"
          *ngIf="
            !isCheckoutRoute &&
            !isProductRoute &&
            !isCloudSpecialist &&
            !isWorkshop &&
            !isAzureDataIa
          "
        >
          <i-feather name="menu" (click)="drawer.toggle()"></i-feather>
        </div>
      </div>
    </div>

    <!-- Modal for Colombia people -->
    <modal-site-colombia
      (responseCol)="getResponseModal($event)"
      *ngIf="isColombia && !modalWasShowed"
    ></modal-site-colombia>

    <!-- Router elemement -->
    <router-outlet></router-outlet>

    <!-- Pre footer as a small component -->
    <div class="wrap-pre-footer" *ngIf="!isCloudSpecialist && !isAzureDataIa">
      <a class="pre-footer" routerLink="/contact/cesar">
        <figure class="pre-footer-img">
          <img src="/assets/homeAssets/avatares-cta.png" alt="People image" />
        </figure>

        <p>
          Comienza a impulsar el aprendizaje y desarrollo continuo de tu fuerza
          laboral
        </p>

        <a href="" class="pre-footer-CTA">
          <span>
            <img
              class="icon"
              src="/assets/homeAssets/icon-user-white.svg"
              alt=""
            />

            Agenda con un asesor</span
          >
          <i-feather name="arrow-right" />
        </a>
      </a>
    </div>

    <div class="footer" *ngIf="footerHidden != true">
      <!-- First part of the component and container -->
      <div class="footer__content">
        <!-- Top part of the footer -->
        <div class="top-side">
          <div class="logo">
            <figure>
              <img [src]="urlAssets2 + 'Logo_escalab.svg'" alt="" />
            </figure>

            <div class="suscribeText">
              Síguenos en nuestras redes sociales y obtén las últimas noticias y
              productos de Escalab Tech.
            </div>

            <div class="bottom-side">
              <ng-container *ngFor="let rs of iconRedesSociales">
                <a [href]="rs.link" class="image" target="_blank">
                  <img [src]="urlAssets2 + rs.src" alt="Logo red social" />
                </a>
              </ng-container>
            </div>
          </div>

          <div class="columns">
            <ng-container *ngFor="let itemFooter of listFooter">
              <div class="content">
                <div class="title">
                  <h6>{{ itemFooter.title }}</h6>
                </div>

                <div class="list">
                  <ng-container *ngFor="let item of itemFooter.items">
                    <h6 (click)="redirectLink(item.link, item.isInternal)">
                      {{ item.text }}
                    </h6>
                  </ng-container>
                </div>
              </div>
            </ng-container>
          </div>
        </div>

        <div class="middle-side">
          <div class="content__partner">
            <div class="titlePatners">Sponsored by:</div>
            <div class="patners">
              <ng-container *ngFor="let img of SponsoredFooter">
                <img [src]="img" alt="Patner img" />
                <img
                  src="/assets/homeAssets/Footer/MicrosoftForStartups.webp"
                  class="microsoft"
                />
              </ng-container>
            </div>
          </div>

          <div class="mobilepatners">
            <div class="titlePatners">Supported by:</div>
            <div class="content__swiper">
              <swiper [config]="configSwiper">
                <ng-template swiperSlide *ngFor="let img of SupportedFooter">
                  <div class="imgContainer">
                    <img [src]="img" alt="Patner img" />
                  </div>
                </ng-template>
              </swiper>
            </div>
          </div>
        </div>
      </div>

      <div class="footer__span">
        <div class="footer__span__links">
          <span
            routerLink="/terminos-y-condiciones"
            class="footer__span__links__link"
            routerLink="/terminos-y-condiciones"
            >Términos y condiciones</span
          >
          <span class="footer__span__links__slash">/</span>
          <span
            routerLink="/politicas-de-privacidad"
            class="footer__span__links__link"
            routerLink="/politicas-de-privacidad"
            >Políticas y privacidad</span
          >
          <span class="footer__span__links__slash">/</span>
          <span class="footer__span__links__link">Mapa del sitio</span>
        </div>

        <div class="footer__span__first">
          <span>© 2022 Todos los derechos reservados por Escalab.</span>
        </div>

        <!-- FIXED COMPONENT AT SOME PAGES -->

        <!-- <div
          *ngIf="this.contactButton"
          class="cta__fixed"
          [ngClass]="{ show: show === true, hidden: !show }"
          #contactButton
        >
          <button routerLink="/contact/cesar">
            <img src="/assets/homeAssets/icon-user-white.svg" alt="" />
            Agendar con un experto
          </button>
        </div> -->
      </div>
    </div>
  </mat-drawer-content>
</mat-drawer-container>
