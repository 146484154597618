import { isPlatformBrowser } from '@angular/common';
import { Injectable, PLATFORM_ID, Inject } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class MessageService {

  constructor( @Inject(PLATFORM_ID) private plataforImd :object ) { }

  public send( message: any ) : void {

    if (isPlatformBrowser(this.plataforImd)) {
      window.postMessage( message, this.getOriginForSelf() );
    }

  }

  private getOriginForSelf() : string {

    // At this time, if the application is being loaded directly off disk (ie, not
    // being served-up as a web-app), then the origin has to be "*" or the message
    // will be denied by the browser. If you never expect to serve from disk, you can
    // omit this edge-case.
    if ( window.location.protocol === "file:" ) {

      return( "*" );

      // If the application is being served-up "proper", then let's lock it down to the
      // current origin.
    } else {

      return( `${ window.location.protocol }//${ window.location.host }` );

    }

  }
}
