import {
  AfterContentInit,
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
  HostListener,
} from '@angular/core';
import { MatSidenav } from '@angular/material/sidenav';
import { NavigationEnd, NavigationStart, Router } from '@angular/router';
import { SwiperOptions } from 'swiper';
import SwiperCore, { Autoplay } from 'swiper';
SwiperCore.use([Autoplay]);

// Static Data
import { BUTTONS_ROUTER, buttonI } from './utils/buttons_router.data';

// Services
import { GetCountryService } from '../for-developers/services/get-country.service';
import { CountryState } from '../for-developers/services/country-state.service';
import { LoaderService } from '@services/loader/loader.service';
import { GetDinamycPriceService } from '@services/bootcamp/get-dinamyc-price.service';
import { dinamycPriceI } from 'src/app/interfaces/dinamycPriceI';

interface CountryRes {
  country_name: string;
}

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss', './scss/footer.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MainComponent implements OnInit, AfterViewInit {
  urlAssets = '../../../../../assets/recruitment/';
  urlAssets2 = '../../../../../assets/homeAssets/';

  developers_routes_content: buttonI = BUTTONS_ROUTER[0];
  events_routes_content: buttonI = BUTTONS_ROUTER[1];

  listFooter = [
    {
      title: 'Soluciones',
      items: [
        {
          text: 'Certificaciones STEM',
          link: '#',
          isInternal: true,
        },
        {
          text: 'Certificaciones Microsoft',
          link: '#',
          isInternal: true,
        },
        {
          text: 'Planes y precios',
          link: '#',
          isInternal: true,
        },
        {
          text: 'Programas de capacitación',
          link: '/products/suite-of-skills-and-competencies',
          isInternal: true,
        },
      ],
    },
    {
      title: 'Sobre Escalab',
      items: [
        {
          text: 'Sobre nosotros',
          link: '#',
          isInternal: true,
        },
        {
          text: 'Contactanos',
          link: '#',
          isInternal: false,
        },
        {
          text: 'Partners y aliados',
          link: '#',
          isInternal: false,
        },
        {
          text: 'Contactanos',
          link: '#',
          isInternal: false,
        },
      ],
    },
  ];

  iconRedesSociales = [
    { src: 'telegram.svg', link: 'https://t.me/+HEdRvh9vvhw0NWMx' },
    { src: 'linkediN.svg', link: 'https://www.linkedin.com/company/escalab/' },
    { src: 'instagram.svg', link: 'https://www.instagram.com/escalab.tech/' },
    { src: 'Facebook.svg', link: 'https://www.facebook.com/escalab.tech' },
    { src: 'Youtube.svg', link: 'https://www.youtube.com/c/EscalabAcademy' },
    { src: 'discord.svg', link: 'https://discord.gg/dFVZyErFZm' },
  ];

  SponsoredFooter = ['/assets/homeAssets/Footer/logo-microsoft-footer.svg'];

  SupportedFooter = [
    '/assets/homeAssets/Footer/logo-startup-peru.png',
    '/assets/homeAssets/Footer/logo-proinnovate.png',
    '/assets/homeAssets/Footer/logo-sumanti.svg',
    '/assets/homeAssets/Footer/logo-corfo.svg',
    '/assets/homeAssets/Footer/logo-imagine-labs.svg',
    '/assets/homeAssets/Footer/logo-ruta-n.svg',
    '/assets/homeAssets/Footer/logo-aws.svg',
    '/assets/homeAssets/Footer/logo-comfama.svg',
  ];

  configSwiper: SwiperOptions = {
    loop: true,
    autoplay: {
      delay: 2000,
      disableOnInteraction: false,
    },
    breakpoints: {
      350: { slidesPerView: 2, spaceBetween: 0 },
      1024: { slidesPerView: 3, spaceBetween: 20 },
      1400: { slidesPerView: 5, spaceBetween: 0 },
    },
    grabCursor: true,
  };

  // Urls
  urlActiveDev: boolean = false;
  urlActiveBus: boolean = true;

  // Banner to be loaded if the place match with Sumanti
  isColombia = false;
  canBeSumanti = false;
  modalWasShowed = false;
  footerHidden = false;
  isCheckoutRoute = false;
  isProductRoute = false;

  isCloudSpecialist = false;
  isAzureDataIa = false;
  isWorkshop = false;

  // This is for know if the button "Contact" if have to be showed
  contactButton: boolean = false;
  urlShowed: string[] = [
    '/products/suite-of-skills-and-competencies',
    '/services/training-programs-for-tech-teams',
    '/services/train-to-hire',
    '/services/prices',
  ];

  // Drawe instance for mobile menu
  @ViewChild('drawer') drawer!: MatSidenav;

  //this variable save dinamyc price
  dinamycPrice?: dinamycPriceI;

  constructor(
    private router: Router,
    private cdRef: ChangeDetectorRef,
    private getCountryService: GetCountryService,
    private countryState: CountryState,
    private loaderService: LoaderService,
    private dinamycPriceService: GetDinamycPriceService,
    private cdr: ChangeDetectorRef
  ) {
    // Load of the country and some components
    this.getCountryDevelopers(router.url);

    // First check of the UI state
    this.checkUI();

    router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        // Conditional to know if the contact buttons have to be showed or hidden
        this.checkUI();
      }
    });
  }

  checkUI() {
    const arrayUrl = this.router.url.split('/');
    arrayUrl.shift();

    //Close sidebar mobile
    if (this.drawer) {
      this.drawer.close();
    }
    // Conditional to know if the contact buttons have to be showed or hidden
    if (
      this.urlShowed.includes(this.router.url) ||
      this.router.url == '/' ||
      this.router.url.includes('/products') ||
      !this.router.url.includes('/products/bootcamp-azure-cloud-specialist')
    ) {
      this.contactButton = true;
    } else {
      this.contactButton = false;
    }

    // Method to delete the footer on payment or some routes
    if (
      this.router.url.includes('checkout') ||
      this.router.url.includes('buy-detail')
    ) {
      this.footerHidden = true;
      this.isCheckoutRoute = true;
    } else {
      this.footerHidden = false;
      this.isCheckoutRoute = false;
    }

    if (arrayUrl[0] == 'products' && arrayUrl.length == 3) {
      this.isProductRoute = true;
    } else {
      this.isProductRoute = false;
    }

    if (
      arrayUrl[0] == 'products' &&
      arrayUrl[1] == 'bootcamp-azure-cloud-specialist'
    ) {
      this.isCloudSpecialist = true;
    } else {
      this.isCloudSpecialist = false;
    }

    if (
      arrayUrl[0] == 'products' &&
      arrayUrl[1].includes('program-azure-data-and-ai')
    ) {
      this.isAzureDataIa = true;
    } else {
      this.isAzureDataIa = false;
    }

    if (
      arrayUrl[0] == 'products' &&
      arrayUrl[1] == 'workshop-azure-fundamentals-az-900-certified'
    ) {
      this.isWorkshop = true;
    } else {
      this.isWorkshop = false;
    }
    console.log(`THIS CLOUND ${this.isCloudSpecialist}`);
    console.log(`THIS AI ${this.isAzureDataIa}`);
  }

  // Method to know the client country
  async getCountryDevelopers(url: string) {
    // Service to get the country
    const country = (await this.getCountryService.getCountryFetch<CountryRes>())
      .country_name;
    // This element save the past data and overwrites the elements what we pass
    this.countryState.sharingObservableData = {
      ...this.countryState.sharingObservable.value,
      country: country,
    };

    if (url.includes('/developers') && country == 'Colombia') {
      this.isColombia = true;
    }
  }

  // This function catch the son response if the modal is showed for Colombia
  getResponseModal(e: boolean) {
    //Changes in the global state
    this.countryState.sharingObservableData = {
      ...this.countryState.sharingObservable.value,
      sumanti: e,
    };

    // This component
    this.canBeSumanti = e;
    this.modalWasShowed = true;
  }

  ngAfterViewInit(): void {
    this.cdRef.markForCheck();
  }

  discountPrice: number | undefined;

  ngOnInit() {
    //this function get dinamyc price and discount price
    this.dinamycPriceService.dataPriceCurrent.subscribe(
      (dataPrice: dinamycPriceI[] | null) => {
        if (dataPrice && Array.isArray(dataPrice) && dataPrice.length) {
          this.dinamycPrice = dataPrice[0];
          console.log('here', this.dinamycPrice);
          this.discountPrice =
            this.dinamycPrice?.value - this.dinamycPrice?.value * 0.1;
          this.cdr.detectChanges();
        }
      }
    );

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0);
        }
        // this.loading = true;
        this.loaderService.setterStateLoader = true;
        this.cdRef.markForCheck();
      }

      if (event instanceof NavigationEnd) {
        if (typeof window !== 'undefined') {
          window.scrollTo(0, 0);
        }
        this.getCountryDevelopers(event.url);
      }
    });
  }

  // !Not used func
  redirectLink(link: string, isInternal: boolean = false) {
    if (isInternal) {
      if (link == '/developers') {
        this.goDevelopers();
      } else {
        this.router.navigate([link]);
      }
    } else {
      window.open(link, '_blank');
    }
  }

  // !Not used func
  goDevelopers() {
    this.router.navigate(['/developers']);
    this.urlActiveDev = true;
    this.urlActiveBus = false;
  }

  // !Not used func
  goBusinesses() {
    this.router.navigate(['/']);
    this.urlActiveDev = false;
    this.urlActiveBus = true;
  }

  movePageToTarget(target: string) {
    const node_div: HTMLElement | null = document.getElementById(target);

    if (node_div) {
      node_div.scrollIntoView({ behavior: 'smooth' });
    }
  }

  //here validate if CTA is vissible or not
  @ViewChild('scrollDetector')
  scrollDetector!: ElementRef;
  show: boolean = false;

  @HostListener('window:scroll', []) onScrollEvent() {
    if (window.scrollY > 200) {
      this.show = true;
    } else {
      this.show = false;
    }
  }
}
