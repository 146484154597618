<div class="signup">
  <div class="signup__header">
    <div class="signup__header__content">
  
      <div class="signup__header__content__subtext">
        REGÍSTRATE Y RECIBE MÁS INFORMACIÓN
      </div>
  
      <div class="signup__header__content__title">
        Bootcamps de Reclutamiento
      </div>
  
    </div>
  </div>

  <div class="signup__content">
    <div class="signup__content__form">
      <div id="hubspotForm"> </div>
    </div>
  </div>
</div>


