import {Component, Input, OnInit} from '@angular/core';

// Types of content
import { buttonI } from '../main/utils/buttons_router.data'; 

@Component({
  selector: 'expansion-panel',
  templateUrl: './expansion-panel.component.html',
  styleUrls: ['./expansion-panel.component.scss'],
})
export class ButtonDevResponsivComponent implements OnInit {
  panelOpenState = false;

  @Input() content!: buttonI;

  titleButton!: string;

  constructor(){
  }

  ngOnInit(): void {
    this.titleButton = this.content.title
  }
}