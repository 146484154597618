import { Injectable } from '@angular/core';

import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoaderService {

  private loaderState: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(true);

  constructor() { }

  get observerLoader(){
    return this.loaderState.asObservable();
  }

  set setterStateLoader(value: boolean){

    this.loaderState.next(value);
  }

}
