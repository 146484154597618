import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';
import { dinamycPriceI } from 'src/app/interfaces/dinamycPriceI';
import { v1EndPoints } from 'src/app/utils/schemas/v1EndPoints';

@Injectable({
  providedIn: 'root',
})
export class GetDinamycPriceService {
  private dataPrice = new BehaviorSubject<dinamycPriceI[] | null>(null);
  dataPriceCurrent = this.dataPrice.asObservable();

  constructor(private http: HttpClient) {}

  //this function get dinamyc price from current country
  getPriceCountry(path: string, iso: string): Observable<dinamycPriceI[]> {
    return this.http.get<dinamycPriceI[]>(
      `${environment.APP_API}${v1EndPoints.products}/get-bootcamp-prices${path}/${iso}/`
    );
  }

  changeData(data: any) {
    this.dataPrice.next(data);
  }
}
