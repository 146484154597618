import {AfterContentInit, AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {SwiperOptions} from "swiper";
import {MatSidenav} from "@angular/material/sidenav";
import {NavigationStart, Router} from "@angular/router";
import {AppReadyEvent} from "./events/app-ready.event";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})

export class AppComponent implements OnInit, AfterContentInit {
  urlAssets = '../../../../../assets/recruitment/'

  hidenComponents!: boolean;

  partners = [
    { logo: 'logo-google-startups-partner.svg' },
    { logo: 'logo-corfo-partner.svg' },
    { logo: 'logo-seedstart-partner.svg' },
    { logo: 'logo-innovacion-uc-partner.png' },
    { logo: 'logo-ruta-n-partner.svg' },
    { logo: 'logo-pygma.svg' },
    { logo: 'logo-pro-chile-partner.png' },
    { logo: 'logo-imagine-labs-partner.svg' },
    { logo: 'logo-microsoft-partner.svg' },
    { logo: 'logo-invexor-partner.png' }
  ]

  listFooter = [
    { title: 'Para Empresas',
      items: [
        { text: 'Contratar Developers', link: '#/recruitment/sign-up', isInternal: true},
        { text: 'Marketplace de reclutamiento', link: '/registro-lista-de-espera-marketplace', isInternal: true },
        { text: 'Bootcamp de reclutamiento', link: '/registro-bootcamps-de-reclutamiento', isInternal: true },
        { text: 'Upskilling para equipos TI', link: '/registro-upskilling', isInternal: true },
      ]
    },
    { title: 'Para Talentos',
      items: [
        { text: 'Quiero ser Developer', link: '/developers', isInternal: true },
        { text: 'Escalab Academy', link: 'https://escalab.tech/', isInternal: false },
        { text: 'Comunidad en Discord', link: 'https://discord.com/invite/wGuGGCYz', isInternal: false },
        { text: 'Comunidad en Telegram', link: 'https://t.me/+HEdRvh9vvhw0NWMx', isInternal: false },
      ]
    },
    { title: 'Sobre Escalab',
      items: [
        { text: 'Prensa y medios', link: 'https://escalab-academy.notion.site/Medios-57bb34f608ba48caa6c5f178c654269d', isInternal: false },
        { text: 'Sobre nosotros', link: '/recruitment/sign-up', isInternal: true },
        { text: 'Programa +500 Digital', link: 'https://digital500.org/', isInternal: false },
        { text: 'Agenda una llamada', link: 'https://meetings.hubspot.com/cesar-aviles', isInternal: false },
      ]
    },
    { title: 'Siguenos',
      items: []
    },
  ]

  iconRedesSociales = [
    { src: 'telegram.svg', link: 'https://t.me/+HEdRvh9vvhw0NWMx' },
    { src: 'linkedin.svg', link: 'https://www.linkedin.com/company/escalab/' },
    { src: 'instagram.svg', link: 'https://www.instagram.com/escalab.tech/' },
    { src: 'facebook.svg', link: 'https://www.facebook.com/escalab.tech' },
    { src: 'youtube.svg', link: 'https://www.youtube.com/c/EscalabAcademy' },
    { src: 'discord.svg', link: 'https://discord.gg/NwWNFc4F' }
  ]

  configSwiper: SwiperOptions = {

    loop: true,
    autoplay: {
      delay: 1,
      disableOnInteraction: false
    },
    speed: 1000,
    grabCursor: true,
    breakpoints: {
      // 640: { slidesPerView: 4, spaceBetween: 0 },
      // 768: { slidesPerView: 4, spaceBetween: 0 },
      1024: { slidesPerView: 8, spaceBetween: 20 }
    }
  };
  urlActive!: Boolean;
  urlActiveDev: boolean = false;
  urlActiveBus: boolean = true;

  @ViewChild('drawer') drawer!: MatSidenav;
  private appReadyEvent: AppReadyEvent;

  constructor(private router: Router, appReadyEvent: AppReadyEvent) {
    this.appReadyEvent = appReadyEvent;
  }


  ngOnInit() {

    this.changeClassMenuButton(this.router.url)

    this.router.events.subscribe((event) => {
      if (event instanceof NavigationStart) {
        this.changeClassMenuButton(event.url)
      }
    })


  }
  ngAfterContentInit() {
    this.appReadyEvent.trigger();
  }

  changeClassMenuButton(url: string){
    if ( url == '/developers'){
      this.urlActiveDev = true;
      this.urlActiveBus = false;
    }else {
      this.urlActiveDev = false;
      this.urlActiveBus = true;
    }
  }

  redirectLink(link: string, isInternal: boolean = false){
    if(isInternal){
      if(link == "/developers"){
        this.goDevelopers()
      }else{

        this.router.navigate([link]);
      }
    }else{
      window.open(link, '_blank');
    }
  }


  goDevelopers() {
    this.router.navigate(['/developers'])
    this.urlActiveDev = true;
    this.urlActiveBus = false;
  }

  goBusinesses() {
    this.router.navigate(['/'])
    this.urlActiveDev = false;
    this.urlActiveBus = true;
  }


}
