import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class BannerService {

  private scrollSumanti$: BehaviorSubject< boolean > = new BehaviorSubject( false );
  statusScroll$ = this.scrollSumanti$.asObservable()
  constructor() { }

  setScroll(){
    this.scrollSumanti$.next(true)
  }
}

