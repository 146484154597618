import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
declare var hbspt: any;

@Component({
  selector: 'app-register-start-hiring',
  templateUrl: './register-start-hiring.component.html',
  styleUrls: ['./register-start-hiring.component.scss'],
})
export class RegisterStartHiringComponent implements OnInit {
  urlAssets = '../../../../../assets/recruitment/';

  title = 'Registro Bootcamps de reclutamiento | Escalab';
  description =
    'Solicita más información sobre nuestros bootcamp de reclutamiento. Regístrate y conecta con nuestro equipo de expertos | Escalab';
  image =
    'https://escalab.academy/cover-escalab-tech/cover-bootcamps-reclutamiento-page.png';

  constructor(private titleService: Title, private meta: Meta) {
    this.meta.addTags([
      { name: 'description', content: this.description },
      { property: 'og:title', content: this.title },
      { property: 'og:description', content: this.description },
      { property: 'og:image', content: this.image },
      { itemprop: 'image', content: this.image },
    ]);
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

  ngAfterContentInit() {
    if (typeof hbspt !== 'undefined') {
      hbspt.forms.create({
        portalId: '8780012',
        formId: '365e80ff-b20c-4c5f-ba5f-77ef03ce2803',
        target: '#hubspotForm',
        region: 'na1',
        version: 'V2_PRERELEASE',
      });
    }
  }
}
