<button
  mat-button
  [matMenuTriggerFor]="developers"
  aria-label="Example icon-button with a menu"
  class="button__router__header"
>
  {{ titleButton }}
  <i-feather name="chevron-down" style="color: #121e40"></i-feather>
</button>

<mat-menu #developers>
  <div class="container_section__route" *ngFor="let section of content.section">
    <p class="container_section__route-title">{{ section.sectionTitle }}</p>

    <div *ngFor="let button of section.content">
      <a
        [href]="button.pathUrl"
        class="button__route"
        *ngIf="!button.outSite; else outSiteLink"
      >
        <p>{{ button.pathName }}</p>
        <span class="bunddleFree" *ngIf="button.free == true">FREE</span>
      </a>
      <ng-template #outSiteLink>
        <a
          [href]="button.pathUrl"
          class="button__route"
          *ngIf="button.outSite"
          target="_blank"
        >
          <p>{{ button.pathName }}</p>
          <span class="bunddleFree" *ngIf="button.free == true">FREE</span>
          <span class="bunddleOffResponsive" *ngIf="button.DCTO == true"
            >80% OFF</span
          >
          <span class="bunddleLive" *ngIf="button.live == true">LIVE</span>
          <span class="bunddleLive" *ngIf="button.custom_tag">{{
            button.custom_tag
          }}</span>
        </a>
      </ng-template>
    </div>
  </div>
</mat-menu>
