// This file was autogenerated by dynamically running setEnv.ts and using dotenv for managing API key secrecy
export const environment = {
  production: true,
  API_URL: 'undefined',
  API_KEY: 'undefined',
  GOOGLE_CLIENT: 'undefined',
  KUSHKI_PUBLIC_KEY: 'undefined',
  APP_NAME: 'ESCALAB-TECH',
  MP_PUBLIC_KEY_CHILE: 'TEST-3bb23cb2-64d2-4c18-a625-3a64c51b55e8',
  MP_PUBLIC_KEY_PERU: 'TEST-95aef8ca-a2b9-494f-8548-0d3e890ea8db',
  MP_PUBLIC_KEY_COLOMBIA: 'TEST-cbcca3c3-079a-4662-b0f6-ffea0901a3ff',
  MP_API_URL: 'https://payment-golang-service-928tg.ondigitalocean.app/',
  PRODUCTS_API_URL: 'https://product-service-container.bluebeach-8ab50082.westus2.azurecontainerapps.io/',
  APP_API: 'https://djangoescalabapp.azurewebsites.net/api',
  API_IP: 'https://api.ipify.org/?format=json',
  API_LOCATION_USER: 'https://atlas.microsoft.com/geolocation/ip/json',
};
