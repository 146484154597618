import { ConnectionPositionPair } from '@angular/cdk/overlay';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';

// Import service
import { LoaderService } from '@services/loader/loader.service';
import { Observable } from 'rxjs';

@Component({
  selector: 'app-loader',
  template: `
    <div
      class="loader"
      [ngClass]="{
        hidden: !loading
      }"
    >
      <div class="container">
          <!-- Borders for the animations -->
          <div class="border borderWhite">
              <span class="borderRadius borderRadiusWTop"></span>
              <span class="borderRadius borderRadiusWBottom"></span>
          </div>
          <div class="border borderBlue">
              <span class="borderRadius borderRadiusBTop"></span>
              <span class="borderRadius borderRadiusBBottom"></span>
          </div>

          <!-- Circles on the midle animations place -->
          <div class="circle circleBlueL">
              <div class="circle circleWhite">
                  <div class="circle circleBlueD">
                      <div class="containTriangle">
                          <img src="../../../../assets/loader/triangleB.svg" alt="" class="triangle triangleBlue">
                          <img src="../../../../assets/loader/triangleW.svg" alt="" class="triangle triangleWhite">
                      </div>
                  </div>
              </div>
          </div>
      </div>
    </div>
  `,
  styleUrls: ['./loader.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoaderComponent implements OnInit, OnDestroy {

  loading !: boolean;
  dataLoading$: Observable<boolean> = this.loaderService.observerLoader;

  @Output() loaderClosed = new EventEmitter<void>();
  @Output() loaderStart = new EventEmitter<void>();
  visibility = 'visible';
  opacity = 1;
  height = '100vh';
  constructor(
    private detector: ChangeDetectorRef,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.dataLoading$.subscribe(loading => {

      this.loading = loading

      if(loading){
        setTimeout(() => {
          this.loading = false;
          this.loaderService.setterStateLoader = false;
        }, 2500);
      }

      this.detector.detectChanges();
    });
  }

  enableLoader(){
      this.height = '100vh';
      this.visibility = 'visible';
      this.opacity = 1;
      this.loaderStart.emit()
      this.detector.markForCheck();
  }

  disableLoader(){
      this.height = '0px';
      this.visibility = 'hidden';
      this.opacity = 0;
      this.loaderClosed.emit()
      this.detector.markForCheck();
  }

  ngOnDestroy(): void {
      this.visibility = 'hidden';
      this.opacity = 0;
      this.detector.markForCheck();
  }

}
