import { Component, Output, EventEmitter } from "@angular/core";

@Component({
  selector: 'modal-site-colombia',
  templateUrl: './modal-site-colombia.component.html',
  styleUrls: ['./modal-site-colombia.component.scss'],
})

class ModalSiteColombiaComponent {

  @Output() responseCol = new EventEmitter<boolean>()

  isSumantiPlace: (boolean | string) = 'Not Filled yet';

  constructor(){}

  // Catch the info sended for her son or the select input place
  getResponseSelect(res: boolean){
    // Saving the res
    this.isSumantiPlace = res;
  }

  sendResponse(){
    // This "if" validate if the input was filled
    if(typeof this.isSumantiPlace == 'boolean'){
      this.responseCol.emit(this.isSumantiPlace);
      return
    }

  }
}

export {ModalSiteColombiaComponent};
