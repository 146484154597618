declare var hbspt: any;
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

// Import observable from rxjs
import { Observable } from 'rxjs';

// Import service country
import {
  CountryObject,
  CountryState,
} from '../for-developers/services/country-state.service';

@Component({
  selector: 'app-register-bootcamp-azure',
  templateUrl: './register-bootcamp-azure.component.html',
  styleUrls: ['./register-bootcamp-azure.component.scss'],
})
export class RegisterBootcampAzureComponent implements OnInit, AfterViewInit {
  // @Input('country') country: string = '';
  urlAssets = '../../../../../assets/recruitment/';

  //varible Observer
  private dataCountry$: Observable<CountryObject> =
    this.countryState.sharingObservableObserver;

  title = 'Aplica a nuestro bootcamp Azure DevOps | Escalab';
  description =
    'Aplica a nuestro bootcamp Azure DevOps sin costo inicial y consigue tu primer empleo en la industria mejor pagada del mundo. Escalab invierte en ti.';
  image = 'https://escalab.tech/cover-escalab-tech/cover-developers-page.png';

  constructor(
    private titleService: Title,
    private meta: Meta,
    private countryState: CountryState
  ) {
    this.meta.addTags([
      { name: 'description', content: this.description },
      { property: 'og:title', content: this.title },
      { property: 'og:description', content: this.description },
      { property: 'og:image', content: this.image },
      { itemprop: 'image', content: this.image },
    ]);
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.dataCountry$.subscribe((res) =>
      this.changeFormHubSpotForm(res.country, res.sumanti)
    );
  }

  changeFormHubSpotForm(country: string, sumanti: string | boolean = false) {
    switch (true) {
      case country == 'Mexico':
        this.createForm('000ebf77-dc21-4d30-9278-159cbd1467ee');
        break;

      default:
        this.createForm('2e8d83c1-b115-4c75-9bb5-57992d8bcac7');
        break;
    }
  }

  createForm(id: string) {
    if (typeof hbspt !== 'undefined') {
      hbspt.forms.create({
        portalId: '8780012',
        formId: id,
        target: '#hubspotForm',
        region: 'na1',
      });
    }
  }

  ngAfterViewInit() {}
}
