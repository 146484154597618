import { Injectable } from '@angular/core';

// Rxjs observable
import { BehaviorSubject } from 'rxjs';

export interface CountryObject {
  country: string,
  sumanti: boolean | string
};

@Injectable({
  providedIn: 'root'
})
export class CountryState {

  private sharingObservablePrivate: BehaviorSubject<CountryObject> = new BehaviorSubject<CountryObject>({ country: 'Chile', sumanti: 'NotSetted' });

  constructor() {}

  // This method get the observer for this prop
  get sharingObservableObserver(){
    return this.sharingObservablePrivate.asObservable();
  }

  // This method get the current value
  get sharingObservable(){
    return this.sharingObservablePrivate
  }

  // This method set the info for the global context
  set sharingObservableData(res: CountryObject){
    this.sharingObservablePrivate.next(res);
  };

}
