import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { HttpClientModule } from '@angular/common/http';

//Import Forms module
import { FormsModule } from '@angular/forms';

import { environment } from 'src/environments/environment';

import { CommonModule, registerLocaleData } from '@angular/common';
import localeES from '@angular/common/locales/es';
import { RouterModule } from '@angular/router';
import { IconsModule } from './shared/icon/icon.module';

import { SwiperModule } from 'swiper/angular';

// Material imports
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSelectModule } from '@angular/material/select';
import { MatMenuModule } from '@angular/material/menu';
import { MatExpansionModule } from '@angular/material/expansion';

// Components
import { MainComponent } from './modules/recruitment/scenes/main/main.component';
import { ButtonRouterComponent } from './modules/recruitment/scenes/router button/router-button.component';
import { LoaderComponent } from './modules/recruitment/scenes/loader/loader.component';
import { BannerComponent } from './modules/recruitment/scenes/banner/banner.component';
registerLocaleData(localeES, 'es');

import { ModalSiteColombiaComponent } from './modules/recruitment/scenes/for-developers/components/modal-site-colombia/modal-site-colombia.component';
import { SelectSiteComponent } from './modules/recruitment/scenes/for-developers/components/select-site/select-site.component';

// Module
import { SharedModule } from './shared/shared.module';
import { ButtonDevResponsivComponent } from '@modules/recruitment/scenes/expansion-panel/expansion-panel.component';
import { ConditionAndTermsComponent } from '@modules/legalPages/condition-terms/condition-terms.component';
import { PrivacyPoliciesComponent } from '@modules/legalPages/privacy-policies/privacy-policies.component';

const { GOOGLE_CLIENT: googleClient = '' } = environment;

@NgModule({
  declarations: [
    AppComponent,

    // Pages
    ConditionAndTermsComponent,
    PrivacyPoliciesComponent,

    // main components
    MainComponent,
    ButtonRouterComponent,
    LoaderComponent,
    BannerComponent,
    ButtonDevResponsivComponent,

    // Family select modal
    ModalSiteColombiaComponent,
    SelectSiteComponent,
  ],
  imports: [
    BrowserModule.withServerTransition({ appId: 'serverApp' }),
    AppRoutingModule,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    CommonModule,
    IconsModule,
    SwiperModule,
    MatSidenavModule,
    MatSelectModule,
    MatMenuModule,
    FormsModule,
    MatExpansionModule,
  ],
  exports: [CommonModule,MatExpansionModule],
  providers: [{ provide: LOCALE_ID, useValue: 'es' }],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
