<div class="signup">
  <div class="signup__header">
    <div class="signup__header__content">
  
      <div class="signup__header__content__subtext">
        APLICA AL BOOTCAMP
      </div>
  
      <div class="signup__header__content__title">
        Postula al Bootcamp y consigue tu primer empleo como DevOps
      </div>
  
    </div>
  </div>

  <div class="signup__content">
      <div class="signup__content__form">
        <div class="mern-img"><img [src]="urlAssets+'Insignia-Bootcamp-Azure-DevOps-AZ400.svg'" alt=""></div>
      <div id="hubspotForm"> </div>
    </div>
  </div>
</div>
