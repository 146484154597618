import {Component, Output, EventEmitter} from '@angular/core';

interface Site {
  value: string;
  viewValue: string;
}

@Component({
  selector: 'Select-site',
  templateUrl: 'select-site.component.html',
  styleUrls: ['select-site.component.scss']
})
export class SelectSiteComponent {

  @Output() place = new EventEmitter<boolean>();

  // Options to be loaded in the modal
  sites: Site[] = [
    {value: 'Medellín', viewValue: 'Medellín'},
    {value: 'Itagui', viewValue: 'Itagui'},
    {value: 'Bello', viewValue: 'Bello'},
    {value: 'Sabaneta', viewValue: 'Sabaneta'},
    {value: 'La estrella', viewValue: 'La estrella'},
    {value: 'Barbosa', viewValue: 'Barbosa'},
    {value: 'Copacabana', viewValue: 'Copacabana'},
    {value: 'Girardota', viewValue: 'Girardota'},
    {value: 'Caldas', viewValue: 'Caldas'},
    {value: 'Envigado', viewValue: 'Envigado'},
    {value: 'Otro', viewValue: 'Otro'},
  ];

  // Current value
  valuePlace!:string;

  constructor(){}

  // Function who update her father
  onChangeInput(e: String){

    // This variable have if the option is selected is a place apply Sumanti.
    const emitRes = (e != 'Otro');
    // For last emit to his father the response.
    this.place.emit(emitRes);
  }
}
