interface sectionRoutes {
  sectionTitle: string;
  content: {
    pathName: string;
    pathUrl: string;
    free: boolean;
    DCTO: boolean;
    outSite: boolean;
    live: boolean;
    custom_tag?: string;
  }[];
  desactive?: boolean;
}

export interface buttonI {
  title: string;
  section: sectionRoutes[];
}

/*
  This buttons have first the title button : Title
  Second a different sections with different routes for areas : sectionRoutes
  third the routes for each product : content
*/
export const BUTTONS_ROUTER: buttonI[] = [
  {
    title: 'Soluciones',
    section: [
      {
        sectionTitle: 'Servicios',
        content: [
          {
            pathUrl: '/products/suite-of-skills-and-competencies',
            pathName: 'Programas de capacitación',
            free: false,
            DCTO: false,
            outSite: false,
            live: false,
          },
        ],
      },
      {
        sectionTitle: 'Productos',
        content: [
          {
            pathUrl: '/products/suite-of-skills-and-competencies',
            pathName: 'Certificaciones STEM',
            free: false,
            DCTO: false,
            outSite: false,
            live: false,
          },
          {
            pathUrl: '/products/microsoft-certifications',
            pathName: 'Certificaciones Microsoft',
            free: false,
            DCTO: false,
            outSite: false,
            live: false,
          },
          {
            pathUrl: '/products/program-azure-data-and-ai',
            pathName: 'Bootcamp Azure Data & AI',
            free: false,
            DCTO: false,
            outSite: false,
            live: false,
          },
          {
            pathUrl: '/products/bootcamp-azure-cloud-specialist',
            pathName: 'Bootcamp Azure Cloud Specialist',
            free: false,
            DCTO: false,
            outSite: false,
            live: false,
          },
        ],
      },
    ],
  },
  {
    title: 'Eventos',
    section: [
      {
        sectionTitle: 'Master class ',
        content: [
          {
            pathUrl:
              'https://landing.escalab.tech/events-new/workshop-fundamentos-de-inteligencia-artificial-y-machine-learning-en-azure',
            pathName:
              'Fundamentos de inteligencia artificial y Machine Learning en Azure. ',
            free: false,
            DCTO: false,
            outSite: true,
            live: false,
            custom_tag: 'Online',
          },
        ],
      },
    ],
  },
];
