declare var hbspt: any;
import { AfterViewInit, Component, Input, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';

// Import observable from rxjs
import { Observable } from 'rxjs';

// Import service country
import {
  CountryObject,
  CountryState,
} from '../for-developers/services/country-state.service';

@Component({
  selector: 'app-register-bootcamp-fullstack-mern',
  templateUrl: './register-bootcamp-fullstack-mern.component.html',
  styleUrls: ['./register-bootcamp-fullstack-mern.component.scss'],
})
export class RegisterBootcampFullstackMernComponent
  implements OnInit, AfterViewInit
{
  // @Input('country') country: string = '';
  urlAssets = '../../../../../assets/recruitment/';

  //varible Observer
  private dataCountry$: Observable<CountryObject> =
    this.countryState.sharingObservableObserver;

  title = 'Aplica a nuestro bootcamp Full Stack Developer JS | Escalab';
  description =
    'Aplica a nuestro bootcamp Full Stack Javascript sin costo inicial y consigue tu primer empleo en la industria mejor pagada del mundo. Escalab invierte en ti.';
  image =
    'https://escalab.academy/cover-escalab-tech/cover-developers-page.png';

  constructor(
    private titleService: Title,
    private meta: Meta,
    private countryState: CountryState
  ) {
    this.meta.addTags([
      { name: 'description', content: this.description },
      { property: 'og:title', content: this.title },
      { property: 'og:description', content: this.description },
      { property: 'og:image', content: this.image },
      { itemprop: 'image', content: this.image },
    ]);
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);

    this.dataCountry$.subscribe((res) =>
      this.changeFormHubSpotForm(res.country, res.sumanti)
    );
  }

  changeFormHubSpotForm(country: string, sumanti: string | boolean = false) {
    switch (true) {
      case country == 'Colombia' && sumanti:
        this.createForm('3b62bed1-adc0-4e6f-af6c-85dd557416ee');
        break;

      case (country == 'Colombia' && !sumanti) || country == 'Chile':
        this.createForm('f1050978-ac12-4dae-bf72-0e2629ca9552');
        break;

      case country == 'Peru' || country == 'Mexico':
        this.createForm('23ac45db-9297-43c9-a7ea-1341010b2278');
        break;

      default:
        this.createForm('8a6cd69c-b227-4f5a-94b7-b29a69244584');
        break;
    }
  }

  createForm(id: string) {
    if (typeof hbspt !== 'undefined') {
      hbspt.forms.create({
        portalId: '8780012',
        formId: id,
        target: '#hubspotForm',
        region: 'na1',
      });
    }
  }

  ngAfterViewInit() {}
}
