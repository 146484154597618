<div
  class="banner_sumanti"
  *ngIf="!TargetOutPage && !hiddenBanner; else eventUpskilling"
>
  <!-- (click)="goSumanti(sumanTi)" -->

  <div class="sumanti-logo">
    <img src="../../../../../assets/recruitment/sumanti-white.svg" alt="" />
  </div>
  <div class="promotion">
    Financia tu carrera con SumanTI y obtén un
    <span class="discount">68% OFF</span>
    <span class="promotion2">Paga en 6, 12, 18 hasta en 24 meses.</span>
  </div>
  <div class="link"><u>Ver más</u></div>
</div>

<!-- This banner is different and needed for news on sales or a campaing similar -->

<ng-template #salesNews>
  <a
    href="https://landing.escalab.tech/curso/sales/80"
    target="_blank"
    *ngIf="!hiddenBanner"
  >
    <div class="banner_sales">
      <div class="sales80-logo">
        <img
          src="../../../../../assets/recruitment/sales/logo-dark-sale.svg"
          alt="Sales 80 logo"
        />
      </div>

      <p class="titleBanner">
        🎁 Ya comenzó Escalab sale 80% off en todas las habilidades tech
      </p>

      <a
        href="https://landing.escalab.tech/curso/sales/80"
        class="redirect"
        target="_blank"
      >
        <p>Ver más</p>
        <span>
          <i-feather name="arrow-right"></i-feather>
        </span>
      </a>
    </div>
  </a>
</ng-template>

<ng-template #eventUpskilling>
  <a
    href="https://landing.escalab.tech/events-new/workshop-fundamentos-de-inteligencia-artificial-y-machine-learning-en-azure"
    target="_blank"
  >
    <div class="banner_event">
      <img
        src="/assets/assetsEvent/speakers/avatar-cesar.png"
        alt="Cesar logo"
      />
      <p class="titleBanner">
        Webinar | Fundamentos de inteligencia artificial y Machine Learning en Azure | 18 junio | 5 PM CL / 3 PM CO, PE / 2 PM MX
        <i-feather name="arrow-right"></i-feather>
      </p>
    </div>
  </a>
</ng-template>
