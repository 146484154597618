import { Injectable } from '@angular/core';
import { countries, timezones } from './data/country.data';
import { HttpClient } from '@angular/common/http';

interface Ip {
  ip: number
}

@Injectable({
  providedIn: 'root'
})
export class GetCountryService {
  constructor(private readonly http: HttpClient) {}

  private readonly API = 'https://api.ipgeolocation.io/ipgeo?apiKey=313abc638e80406b943a686f74896fca&ip=';
  private ip!:number;
  country = 'chile';


  getIpAddress(){
    return this.http.get<Ip>('https://api.ipify.org/?format=json');
  }

  async getCountryFetch<Ttype>(): Promise<Ttype>{

    const res = await fetch(this.API)
    const data = res.json();

    return data;
  }

  getCountry ():string{
    const timezone: string = Intl.DateTimeFormat().resolvedOptions().timeZone;

    if (!timezone || timezone === "") return 'Chile';

    const countryAbbreviation = timezones[timezone].c[0];

    this.country = countries[countryAbbreviation];

    return this.country;
  }
}




