import { Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
declare var hbspt: any;

@Component({
  selector: 'app-register-upskilling',
  templateUrl: './register-upskilling.component.html',
  styleUrls: ['./register-upskilling.component.scss'],
})
export class RegisterUpskillingComponent implements OnInit {
  urlAssets = '../../../../../assets/recruitment/';

  title = 'Registro Upskilling para equipos Tech | Escalab';
  description =
    'Solicita más información sobre nuestros programas de capacitación y upskilling. Regístrate y conecta con nuestro equipo de expertos | Escalab';
  image = 'https://escalab.tech/cover-escalab-tech/cover-upskilling-page.png';

  constructor(private titleService: Title, private meta: Meta) {
    this.meta.addTags([
      { name: 'description', content: this.description },
      { property: 'og:title', content: this.title },
      { property: 'og:description', content: this.description },
      { property: 'og:image', content: this.image },
      { itemprop: 'image', content: this.image },
    ]);
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

  ngAfterContentInit() {
    if (typeof hbspt !== 'undefined') {
      hbspt.forms.create({
        portalId: '8780012',
        formId: 'ae68a3be-2764-45c1-b70e-7acf28716bce',
        target: '#hubspotForm',
        region: 'na1',
        version: 'V2_PRERELEASE',
      });
    }
  }
}
