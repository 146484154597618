<div class="containButton">
  <mat-accordion>
    <mat-expansion-panel
      (opened)="panelOpenState = true"
      (closed)="panelOpenState = false"
      class="DropDown"
    >
      <mat-expansion-panel-header>
        <mat-panel-title class="TitleDrop">{{ content.title }}</mat-panel-title>
      </mat-expansion-panel-header>

      <div class="Category" *ngFor="let section of content.section">
        <h1 class="TitleCategory">{{ section.sectionTitle }}</h1>
        <div class="ContainItem" *ngFor="let button of section.content">
          <a
            [href]="button.pathUrl"
            style="display: flex; justify-content: center; align-items: center"
          >
            <h2 class="TitleItem">{{ button.pathName }}</h2>
            <span class="bunddleFreeResponsive" *ngIf="button.free == true"
              >FREE</span
            >
            <span class="bunddleOffResponsive" *ngIf="button.DCTO == true"
              >80% OFF</span
            >
            <span class="bunddleLiveResponsive" *ngIf="button.live == true"
              >LIVE</span
            >
          </a>
        </div>
      </div>
    </mat-expansion-panel>
  </mat-accordion>
</div>
