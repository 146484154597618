import {
  ChangeDetectionStrategy,
  Component,
  HostListener,
  OnInit,
  Input,
} from '@angular/core';
import { BannerService } from 'src/app/services/banner.service';

// Import utils needed
import { Router } from '@angular/router';

@Component({
  selector: 'escalab-banner',
  templateUrl: './banner.component.html',
  styleUrls: ['./banner.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BannerComponent implements OnInit {
  // UI CONFIG VARIABLES
  hiddenBanner!: boolean;

  @HostListener('click', ['$event'])
  handleClick(event: any) {
    if (!this.TargetOutPage) {
      this.bannerService.setScroll();
      return;
    }
  }

  @Input() TargetOutPage!: boolean;

  constructor(private bannerService: BannerService, private router: Router) {
    if (
      router.url.includes('checkout') ||
      router.url.includes('buy-detail') ||
      router.url.includes('category') ||
      router.url.includes('contact')
    )
      this.hiddenBanner = true;
  }

  ngOnInit(): void {}
}
