import { AfterViewInit, Component, OnInit } from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
declare var hbspt: any;

@Component({
  selector: 'app-register-marketplace',
  templateUrl: './register-marketplace.component.html',
  styleUrls: ['./register-marketplace.component.scss'],
})
export class RegisterMarketplaceComponent implements OnInit, AfterViewInit {
  urlAssets = '../../../../../assets/recruitment/';

  title = 'Regístrate para el lanzamiento de nuestro Marketplace | Escalab';
  description =
    'Regístrate para el lanzamiento del primer marketplace de habilidades y empleabilidad tecnológica de Latam | Escalab';
  image =
    'https://escalab.academy/cover-escalab-tech/cover-marketplace-page.png';

  constructor(private titleService: Title, private meta: Meta) {
    this.meta.addTags([
      { name: 'description', content: this.description },
      { property: 'og:title', content: this.title },
      { property: 'og:description', content: this.description },
      { property: 'og:image', content: this.image },
      { itemprop: 'image', content: this.image },
    ]);
  }

  ngOnInit() {
    this.titleService.setTitle(this.title);
  }

  ngAfterViewInit() {
    if (typeof hbspt !== 'undefined') {
      hbspt.forms.create({
        portalId: '8780012',
        formId: 'd3c1da3a-0e49-4de1-9497-891426c038ae',
        target: '#hubspotForm',
        region: 'na1',
        version: 'V2_PRERELEASE',
      });
    }
  }
}
