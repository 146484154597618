import {Component, Input, OnInit} from '@angular/core';

// Types of content
import { buttonI } from '../main/utils/buttons_router.data';

@Component({
  selector: 'router-button',
  templateUrl: './router-button.component.html',
  styleUrls: ['./router-button.component.scss'],
})
export class ButtonRouterComponent implements OnInit{

  @Input() content!: buttonI;

  titleButton!: string;

  constructor(){
  }

  ngOnInit(): void {
    this.titleButton = this.content.title
  }
}
