<div class="modalBackground">
  <div>
    <span>
      <i-feather name="map-pin"></i-feather>
    </span>
    <p>Ayuda a saber tu ubicación para ofrecerte un mejor servicio</p>

    <!-- Component who detect if the place is a selected to be Sumanti -->
    <Select-site (place)="getResponseSelect($event)"></Select-site>

    <!-- Emitter to her father -->
    <div>
      <button type="button" (click)="sendResponse()">Aplicar</button>
    </div>
  </div>
</div>
