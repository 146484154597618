import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';

import { MainComponent } from './modules/recruitment/scenes/main/main.component';
import { AppComponent } from './app.component';
import { RegisterStartHiringComponent } from './modules/recruitment/scenes/register-start-hiring/register-start-hiring.component';
import { RegisterUpskillingComponent } from './modules/recruitment/scenes/register-upskilling/register-upskilling.component';
import { RegisterMarketplaceComponent } from './modules/recruitment/scenes/register-marketplace/register-marketplace.component';
import { RegisterBootcampFullstackMernComponent } from './modules/recruitment/scenes/register-bootcamp-fullstack-mern/register-bootcamp-fullstack-mern.component';
import { ThanksYouBusinessesComponent } from './modules/recruitment/scenes/thanks-you-businesses/thanks-you-businesses.component';
import { ThanksYouUpskillingComponent } from './modules/recruitment/scenes/thanks-you-upskilling/thanks-you-upskilling.component';
import { ThanksYouMarketplaceComponent } from './modules/recruitment/scenes/thanks-you-marketplace/thanks-you-marketplace.component';
import { ThanksYouBootcampFullstackMernComponent } from './modules/recruitment/scenes/thanks-you-bootcamp-fullstack-mern/thanks-you-bootcamp-fullstack-mern.component';
import { ThanksYouColombiaComponent } from './modules/recruitment/scenes/thanks-you-colombia/thanks-you-colombia.component'; //Añadido SC 28-10
import { RegisterBootcampAzureComponent } from '@modules/recruitment/scenes/register-bootcamp-azure/register-bootcamp-azure.component';
import { ThanksYouBootcampAzureDevopsComponent } from '@modules/recruitment/scenes/thanks-you-bootcamp-azure-devops/thanks-you-bootcamp-azure-devops.component';
import { ConditionAndTermsComponent } from '@modules/legalPages/condition-terms/condition-terms.component';
import { PrivacyPoliciesComponent } from '@modules/legalPages/privacy-policies/privacy-policies.component';

const routes: Routes = [
  { path: '', redirectTo: '/', pathMatch: 'full' },
  {
    path: '',
    component: AppComponent,

    children: [
      {
        path: '',
        component: MainComponent,
        children: [
          // Home Route
          {
            path: '',
            loadChildren: () =>
              import('@modules/home/home.module').then((m) => m.HomeModule),
          },

          // !ALL PRODUCTS
          // Product suite
          {
            path: 'products/suite-of-skills-and-competencies',
            loadChildren: () =>
              import('@modules/suite-skills/suite-skills.module').then(
                (m) => m.SuiteSkillsModule
              ),
          },
          {
            path: 'products/microsoft-certifications',
            loadChildren: () =>
              import(
                '@modules/microsoft-certifications/microsoft-certifications.module'
              ).then((m) => m.MicrosoftCertificationsModule),
          },
          {
            path: 'products/bootcamp-azure-cloud-specialist',
            loadChildren: () =>
              import(
                '@modules/bootcamp-cloud-specialist/bootcamp-cloud-specialist.module'
              ).then((m) => m.BootcampCloudSpecialistModule),
          },
          {
            path: 'products/program-azure-data-and-ai',
            loadChildren: () =>
              import(
                '@modules/bootcamp-azure-data-ia/bootcamp-azure-data-ia.module'
              ).then((m) => m.BootcampAzureDataIaModule),
          },
          {
            path: 'products/workshop-azure-fundamentals-az-900-certified',
            loadChildren: () =>
              import(
                '@modules/workshop-azure-z900/workshop-azure-z900.module'
              ).then((m) => m.WorkshopAzureZ900Module),
          },
          // product ones info
          {
            path: 'products',
            loadChildren: () =>
              import('@modules/products/products.module').then(
                (m) => m.ProductsModule
              ),
          },

          // !CHECKOUT ROUTES
          // Checkout for buys
          {
            path: 'checkout',
            loadChildren: () =>
              import('./modules/checkout/checkout.module').then(
                (m) => m.CheckoutModule
              ),
          },
          // Buy detail
          {
            path: 'buy-detail',
            loadChildren: () =>
              import('./modules/buy-detail/buy-detail.module').then(
                (m) => m.BuyDetailModule
              ),
          },

          // !FOR BUSSINES
          // upskilling and Reskilling Routes
          {
            path: 'services/training-programs-for-tech-teams',
            loadChildren: () =>
              import(
                '@modules/upskilling-reskilling/upskilling-reskilling.module'
              ).then((m) => m.UpskillingReskillingModule),
          },
          {
            path: 'services/train-to-hire',
            loadChildren: () =>
              import(
                '@modules/bootcamp-recruiting/bootcamp-recruiting.module'
              ).then((m) => m.BootcampRecruitingModule),
          },
          {
            path: 'services/prices',
            loadChildren: () =>
              import('@modules/prices/prices.module').then(
                (m) => m.PricesModule
              ),
          },

          // !FOR DEVELOPERS
          // Bootcamps developers
          {
            path: 'developers/bootcamp-fullstack-mern',
            // Redirected 04/03/2024
            redirectTo: '/',
            pathMatch: 'full',

            // loadChildren: () =>
            //   import(
            //     './modules/recruitment/scenes/for-developers/for-developers.module'
            //   ).then((m) => m.ForDevelopersModule),
          },
          {
            path: 'developers/bootcamp-azure-microsoft-devops',

            // Redirected 04/03/2024
            redirectTo: '/',
            pathMatch: 'full',

            // loadChildren: () =>
            //   import(
            //     './modules/recruitment/scenes/for-bootcampAzure/for-bootcampAzure.module'
            //   ).then((m) => m.ForDevelopersModule),
          },

          // Error page
          {
            path: 'error-payment',
            loadChildren: () =>
              import('./modules/error-buy/error-buy.module').then(
                (m) => m.ErroBuyModule
              ),
          },

          // Contact page
          {
            path: 'contact',
            loadChildren: () =>
              import('./modules/contact/contact.module').then(
                (m) => m.ContactModule
              ),
          },

          // blog pages -> inside routes
          {
            path: 'blog',
            loadChildren: () =>
              import('./modules/blog/blog.module').then((m) => m.BlogModule),
          },

          {
            path: 'registro-bootcamps-de-reclutamiento',
            component: RegisterStartHiringComponent,
          },
          {
            path: 'registro-upskilling',
            component: RegisterUpskillingComponent,
          },
          {
            path: 'registro-lista-de-espera-marketplace',
            component: RegisterMarketplaceComponent,
          },
          {
            path: 'registro-bootcamp-fullstack-mern',
            component: RegisterBootcampFullstackMernComponent,
          },
          {
            path: 'registro-bootcamp-azure',
            component: RegisterBootcampAzureComponent,
          },

          //Routes thanks you pages
          {
            path: 'thank-you-page',
            loadChildren: () =>
              import('@modules/thaks-pages/thaks-pages.module').then(
                (m) => m.ThaksPagesModule
              ),
          },

          //routers for legal pages
          {
            path: 'terminos-y-condiciones',
            // Redirected 04/03/2024
            redirectTo: '/',
            pathMatch: 'full',

            // component: ConditionAndTermsComponent,
          },
          {
            path: 'bootcamp-azure-certified',
            // Redirected 04/03/2024
            redirectTo: '/',
            pathMatch: 'full',

            // component: ConditionAndTermsComponent,
          },
          {
            path: 'politicas-de-privacidad',
            // Redirected 04/03/2024
            redirectTo: '/',
            pathMatch: 'full',

            // component: PrivacyPoliciesComponent,
          },
        ],
      },
    ],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
      initialNavigation: 'enabledBlocking',
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
