<mat-form-field appearance="fill" class="select">
  <mat-label>Selecciona Ubicación</mat-label>

  <!-- Option select -->
  <mat-select [(ngModel)]="valuePlace" (valueChange)="onChangeInput($event)">
    <!-- For each place in "Sumanti" a option-->
    <mat-option *ngFor="let site of sites" [value]="site.value">
      {{ site.viewValue }}
    </mat-option>
  </mat-select>
</mat-form-field>
